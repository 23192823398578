<template>
  <div class="login_wrap">
    <h4>欢迎登陆</h4>
    <van-form @submit="onSubmit">
      <van-field
        v-model="username"
        name="username"
        label="用户名"
        placeholder="用户名"
        :rules="[{ required: true, message: '请填写用户名' }]"
      />
      <van-field
        v-model="password"
        type="password"
        name="password"
        label="密码"
        placeholder="密码"
        :rules="[{ required: true, message: '请填写密码' }]"
      />
      <div style="margin: 16px">
        <van-button round block type="info" native-type="submit"
          >提交</van-button
        >
      </div>
    </van-form>
  </div>
</template>

<script>
import axios from "axios";
import cookies from "@/utils/cookies.js";
import { mapMutations } from "vuex";
const dyBaseUrl = process.env.VUE_APP_CRM_BASE_URL;
export default {
  data() {
    return {
      username: "",
      password: "",
    };
  },
  methods: {
    ...mapMutations(["setUserInfo"]),
    onSubmit(data) {
      data.eFingerprint = "moblie-crm";
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      axios({
        method: "POST",
        baseURL: dyBaseUrl,
        data,
        url: `/cas/oauth/token`,
      })
        .then(({ data }) => {
          console.log(data)
          this.$toast.clear();
          if (data.code !== 200) {
            this.$toast.fail(data.errmsg || "登陆出错");
          } else {
            // {"id":"124384717897203712","phone":13826013727,"name":"dev","avatar":null,"last_login_time":"2021-03-19 19:40:24","remember_token":"0","ctime":"2020-07-16 13:48:35","mtime":"2021-03-20 11:28:48","state":1,"manufactor_id":"146,160,58,8,40,186,492","errcode":200,"is_first_bind":false,"token":"124384717897203712-d6deeb6d-ad2d-4cf7-9e19-185dd6f6c160","dealer_id":"186","manufactor_name":null}
            if (data.data.token) {
              cookies.setCookies("token", data.data.token_head + data.data.token);
              this.setUserInfo(data)
              this.$router.push({ name: 'home', query: { userId: data.data.id } });
            } else {
              this.$toast.fail("没有token");
            }
          }
        })
        .catch((err) => {
          console.error(err);
          this.$toast.fail("网络出错");
        });
    },
  },
};
</script>
<style lang="less" scoped>
.login_wrap {
  > h4 {
    padding-bottom: 20px;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
  }
  height: 100vh;
  width: 100%;
  background: #f1f1f1;
  padding: 180px 20px 0;
}
</style>